import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'reactstrap'
import '../styles/about.css'

export default function about() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Maker Recipe Sdn. Bhd. - About Us</title>
            </Helmet>

            <div className="AboutUs">
                <Container>
                    <Row>
                        <Col md="3">
                            <div className="MemoryPhoto MemoryPhoto1">
                                <StaticImage 
                                    src="../images/aboutUsImages/about1.jpg" 
                                    alt="Memory1"
                                    width={130}
                                    height={130} />
                            </div>
                            <div className="MemoryPhoto MemoryPhoto2">
                                <StaticImage 
                                    src="../images/aboutUsImages/about2.jpg" 
                                    alt="Memory2"
                                    width={130}
                                    height={130} />
                            </div>
                            <div className="MemoryPhoto MemoryPhoto3">
                                <StaticImage 
                                    src="../images/aboutUsImages/about3.jpg" 
                                    alt="Memory3"
                                    width={130}
                                    height={130} />
                            </div>
                        </Col>
                        <Col md="9" className="CompanyProfile">
                            <Row>
                                <Col md="12">
                                    <h2>Company Profile</h2>
                                </Col>
                                <Col md="12">
                                    <p>
                                        Maker Recipe Sdn Bhd is a Bumiputera company established on
                                        June 2007. Operates as a manufacturer processing Halal food products
                                        own brand as well as OEM. The company's factory location is located approximately 50km (45 minutes) from
                                        Alor Setar, located at Lot 221, Jalan PKNK 5, Bukit Kayu Hitam Industrial Estate, 06050, Kedah, Malaysia. We 
                                        Own the Copyright and produce products registered under the brand Cap ‘O’, MRSB (abbreviation of 
                                        the company name) now produces various types of spices and soups. Possess Valid Nutrition Quality standards by 
                                        the Malaysian Government such as MESTI & Halal. Types of products that come in various sizes (SKU) that are 
                                        specially designed to meet the demands of the local and foreign retail market by ensuring that product quality 
                                        is always at the highest level. Lined up with experienced and dedicated staff, MRSB is now making strides 
                                        towards becoming one of the leading producers of spices and curry powder in Malaysia.

                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='Certifications'>
                <Container>
                    <Row>
                        <Col md="12" className="certSectionTitle">
                            <h2>Certifications</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="halalcert1">
                                <StaticImage 
                                    src="../images/aboutUsImages/certification/halalcerts1.JPG" 
                                    alt="Sijil Pengesahan Halal"
                                    width={500}
                                    height={700} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <div className="halalcert2">
                                <StaticImage 
                                    src="../images/aboutUsImages/certification/halalcerts2.JPG" 
                                    alt="Sijil Pengesahan Halal"
                                    width={500}
                                    height={700} />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mesticert">
                                <StaticImage 
                                    src="../images/aboutUsImages/certification/mesticerts.JPG" 
                                    alt="Sijil Mesti"
                                    width={500}
                                    height={650} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='Activities'>
                <Container>
                    <Row>
                        <Col md="12" className='activitiesSectionTitle'>
                            <h2>Activities & Participation</h2>
                        </Col>
                    </Row>
                    <Row className='activitesFirstRow'>
                        <Col md="6" className='lawatanDrMaza activity'>
                            <Row>
                                <Col md="12" className='activityTitle'>
                                    <p>Visit from Dato' Dr Maza</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <StaticImage 
                                        src="../images/aboutUsImages/activites/lawatanDrMaza.jpg" 
                                        alt="Visit from Dato' Dr Maza"
                                        width={700}
                                        height={500} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6" className='hariMalaysia activity'>
                            <Row>
                                <Col md="12" className='activityTitle'>
                                    <p>Kibaran Hari Malaysia @ AJ best chow kit</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <StaticImage 
                                        src="../images/aboutUsImages/activites/kibaranHariMalaysia.jpg" 
                                        alt="Kibaran Hari Malaysia @ AJ best chow kit"
                                        width={700}
                                        height={500} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className='wanitaHariIni activity'>
                            <Row>
                                <Col md="12" className='activityTitle'>
                                    <p>Cap 'O' dalam Wanita Hari Ini</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <StaticImage 
                                        src="../images/aboutUsImages/activites/capODalamWHI.jpg" 
                                        alt="Cap 'O' dalam Wanita Hari Ini"
                                        width={700}
                                        height={500} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6" className='programSantuniAsnaf activity'>
                            <Row>
                                <Col md="12" className='activityTitle'>
                                    <p>Santuni Asnaf Program with Lembaga Zakat Negeri Kedah</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <StaticImage 
                                        src="../images/aboutUsImages/activites/programSantuniAsnaf.jpg" 
                                        alt="Santuni Asnaf Bersama Lembaga Zakat Kedah"
                                        width={700}
                                        height={500} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="MissionVision">
                <Container>
                    <Row>
                        <Col md="6" className="missionColumn">
                            <Row>
                                <Col md="12" className="missionTitle">
                                    <h2>Our Mission</h2>
                                </Col>
                                <Col md="12" className="missionSubtitle">
                                    <p>
                                        Our mission has always been to become the leading food manufacturer 
                                        in Malaysia that not only supports the economy but also the community by 
                                        providing community services and a variety of special programs through Yayasan 
                                        Uhud Madaniyah to help our employees. 
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md="6">
                            <Row>
                                <Col md="12" className="visionTitle">
                                    <h2>Our Vision</h2>
                                </Col>
                                <Col md="12" className="visionSubtitle">
                                    <p>
                                        Our Vision is to become a brand where our consumers can conveniently find everywhere 
                                        and can whole heartedly trust that our products are 100% Halal and Toyiban.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div className="History">
                <Container>
                    <Row>
                        <Col md="12" className="historyTitle">
                            <h2>Our History</h2>
                        </Col>
                        <Col md="12" className="historySubtitle">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                                irure dolor in reprehenderit in voluptate velit esse cillum 
                                dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                                cupidatat non proident, sunt in culpa qui officia deserunt 
                                mollit anim id est laborum.
                            </p>
                        </Col>
                        <Col md="12" className="historySubtitle">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                                irure dolor in reprehenderit in voluptate velit esse cillum 
                                dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                                cupidatat non proident, sunt in culpa qui officia deserunt 
                                mollit anim id est laborum.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </Layout>
    )
}